import React from "react";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
// import Header from "../components/Header"
import Footer from "../components/Footer";
import "../css/Markets.scss";

import { Helmet } from "react-helmet";

import logoDomKultur from "../images/Markets/logo-dom-kultur.png";
import logoNitka from "../images/Markets/logo-nitka.png";
import logoVeter from "../images/Markets/logo-veter.png";
import logoGorodSad from "../images/Markets/logo-gorod-sad.png";
import logoLavka from "../images/Markets/logo-lavka.png";
import logoBiobrot from "../images/Markets/logo-biobrot.png";
import logoUsadba from "../images/Markets/logo-usadba.png";
import logoBolotov from "../images/Markets/logo-bolotov.png";
import logoMarkLevRestaurant from "../images/Markets/logo-mark-lev-restaurant.png";
import logoMarkLevKalachnaya from "../images/Markets/logo-mark-lev-kalachnaya.png";
import logoMrCup from "../images/Markets/logo-mr-cup.png";
import logoKofeKult from "../images/Markets/logo-kofe-kult.png";

const Markets = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <Helmet>
        <title>{t("header")}</title>
      </Helmet>
      {/* <Header /> */}
      <main>
        <div className="page page-markets">
          <div className="page-title w-2/3 md:w-auto">
            <div className="page-title__name">{t("title")}</div>
          </div>
          <div className="page-header">
            <h1>{t("header")}</h1>
          </div>
          {/* --- */}
          {/* <Trans>{t("title")}</Trans> */}
          {/* <h1>{t("key")}</h1> */}
          {/* <h1>{t("look")}</h1> */}
          {/* <h1>{t("deep")}</h1> */}
          {/* <h1>{t("look.deep")}</h1> */}
          {/* --- */}
          <div className="page-subheader">
            <h2>{t("subheader1")}</h2>
          </div>
          <div className="row box box_markets">
            <div className="col box-market w-full md:w-1/2">
              <div className="row row-market">
                <div className="col w-1/3">
                  <img
                    className="box__pic box__pic_map"
                    src={logoLavka}
                    alt={t("yaLavkaTitle")}
                  />
                </div>

                <div className="col box__text w-2/3">
                  <div className="box-market__name">{t("yaLavkaTitle")}</div>
                  <div className="box-market__address">
                    {t("yaLavkaAddress")}
                  </div>
                </div>
              </div>
            </div>
            <div className="col box-market w-full md:w-1/2">
              <div className="row row-market">
                <div className="col w-1/3">
                  <img
                    className="box__pic box__pic_map"
                    src={logoDomKultur}
                    alt={t("domCulturTitle")}
                  />
                </div>

                <div className="col box__text w-2/3">
                  <div className="box-market__name">{t("domCulturTitle")}</div>
                  <div className="box-market__address">
                    {t("domCulturAddress")}
                  </div>
                </div>
              </div>
            </div>
            <div className="col box-market w-full md:w-1/2">
              <div className="row row-market">
                <div className="col w-1/3">
                  <img
                    className="box__pic box__pic_map"
                    src={logoGorodSad}
                    alt={t("gorodSadTitle")}
                  />
                </div>

                <div className="col box__text w-2/3">
                  <div className="box-market__name">{t("gorodSadTitle")}</div>
                </div>
              </div>
            </div>

            <div className="col box-market w-full md:w-1/2">
              <div className="row row-market">
                <div className="col w-1/3">
                  <img
                    className="box__pic box__pic_map"
                    src={logoNitka}
                    alt={t("nitkaTitle")}
                  />
                </div>

                <div className="col box__text w-2/3">
                  <div className="box-market__name">{t("nitkaTitle")}</div>
                  <div className="box-market__address">{t("nitkaAddress")}</div>
                </div>
              </div>
            </div>

            <div className="col box-market w-full md:w-1/2">
              <div className="row row-market">
                <div className="col w-1/3">
                  <img
                    className="box__pic box__pic_map"
                    src={logoVeter}
                    alt={t("veterTitle")}
                  />
                </div>

                <div className="col box__text w-2/3">
                  <div className="box-market__name">{t("veterTitle")}</div>
                  <div className="box-market__address">{t("veterAddress")}</div>
                </div>
              </div>
            </div>

            <div className="col box-market w-full md:w-1/2">
              <div className="row row-market">
                <div className="col w-1/3">
                  <img
                    className="box__pic box__pic_map"
                    src={logoBiobrot}
                    alt={t("biobrotTitle")}
                  />
                </div>

                <div className="col box__text w-2/3">
                  <div className="box-market__name">{t("biobrotTitle")}</div>
                  <div className="box-market__address">
                    {t("biobrotAddress")}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="page-subheader page-subheader_not-first">
            <h2>{t("subheader2")}</h2>
          </div>
          <div className="row box box_markets">
            <div className="col box-market w-full md:w-1/2">
              <div className="row row-market">
                <div className="col w-1/3">
                  <img
                    className="box__pic box__pic_map"
                    src={logoUsadba}
                    alt={t("usadbaTitle")}
                  />
                </div>

                <div className="col box__text w-2/3">
                  <div className="box-market__name">{t("usadbaTitle")}</div>
                  <div className="box-market__address">
                    {t("usadbaAddress")}
                  </div>
                </div>
              </div>
            </div>
            <div className="col box-market w-full md:w-1/2">
              <div className="row row-market">
                <div className="col w-1/3">
                  <img
                    className="box__pic box__pic_map"
                    src={logoBolotov}
                    alt={t("bolotovTitle")}
                  />
                </div>

                <div className="col box__text w-2/3">
                  <div className="box-market__name">{t("bolotovTitle")}</div>
                  <div className="box-market__address">
                    {t("bolotovAddress")}
                  </div>
                </div>
              </div>
            </div>

            <div className="col box-market w-full md:w-1/2">
              <div className="row row-market">
                <div className="col w-1/3">
                  <img
                    className="box__pic box__pic_map"
                    src={logoMarkLevRestaurant}
                    alt={t("markLevRestaurantTitle")}
                  />
                </div>

                <div className="col box__text w-2/3">
                  <div className="box-market__name">
                    {t("markLevRestaurantTitle")}
                  </div>
                  <div className="box-market__address">
                    {t("markLevRestaurantAddress")}
                  </div>
                </div>
              </div>
            </div>
            <div className="col box-market w-full md:w-1/2">
              <div className="row row-market">
                <div className="col w-1/3">
                  <img
                    className="box__pic box__pic_map"
                    src={logoMarkLevKalachnaya}
                    alt={t("markLevKalachnayaTitle")}
                  />
                </div>

                <div className="col box__text w-2/3">
                  <div className="box-market__name">
                    {t("markLevKalachnayaTitle")}
                  </div>
                  <div className="box-market__address">
                    {t("markLevKalachnayaAddress")}
                  </div>
                </div>
              </div>
            </div>

            <div className="col box-market w-full md:w-1/2">
              <div className="row row-market">
                <div className="col w-1/3">
                  <img
                    className="box__pic box__pic_map"
                    src={logoMrCup}
                    alt={t("mrCupTitle")}
                  />
                </div>

                <div className="col box__text w-2/3">
                  <div className="box-market__name">{t("mrCupTitle")}</div>
                  <div className="box-market__address">{t("mrCupAddress")}</div>
                </div>
              </div>
            </div>
            <div className="col box-market w-full md:w-1/2">
              <div className="row row-market">
                <div className="col w-1/3">
                  <img
                    className="box__pic box__pic_map"
                    src={logoKofeKult}
                    alt={t("cofeCultTitle")}
                  />
                </div>

                <div className="col box__text w-2/3">
                  <div className="box-market__name">{t("cofeCultTitle")}</div>
                  <div className="box-market__address">
                    {t("cofeCultAddress")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </Layout>
  );
};
export default Markets;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["buy", "common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
